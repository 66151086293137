import * as FullCalendar from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
var Routing = require('../routing');

export function setFullCalendar($container = null)
{
    if ($container == null) {
        $container = $('body');
    }

    $container.find('.fullcalendar').each(function()
    {
        var type = $(this).data('type');
        var url = Routing.generate('visiteur_evenement_calendrier_list_by_date', {'type' : type});
        var calendarEl = this;
        var calendar = new FullCalendar.Calendar(calendarEl, {
            headerToolbar: {
                start: 'prev,next', // will normally be on the left. if RTL, will be on the right
                center: 'title',
                end: '',
                
            },
            height: 500,
            plugins: [dayGridPlugin],
            initialView: 'dayGridMonth',
            navLinks: false,
            editable: false,
            events: url,
            locale: $('html').attr('lang'),
            eventDisplay: 'block',
            displayEventTime: false
        });
        calendar.render();
    });
}