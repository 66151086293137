// BOOTSTRAP
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

// JQUERY
import './lib/visiteur/jquery-1.11.1.min'

// SLICK
import './styles/visiteur/slick.css';
import './styles/visiteur/slick-theme.css';
import './lib/visiteur/slick.min'

// TOASTR FLASHBAG
import 'toastr/build/toastr.min.css';
import './styles/visiteur/toastr.css';
import * as flashBagFunctions from "./lib/flashbag.config";

//JQUERY VALIDATTION
import 'jquery-validation'
import 'jquery-validation/dist/localization/messages_fr.min.js';
import 'jquery-validation/dist/additional-methods.min.js';
import './lib/jquery.validate.config'

//IMASK
import * as imaskConfig from "./lib/jquery.mask.config";

// SUMMERNOTE
import 'summernote/dist/summernote.min.css';
import './styles/visiteur/summernote.css';
import * as summernoteConfig from './lib/summernote.config'

// SELECT2
import 'select2/dist/css/select2.min.css';
import './styles/select2.bootstrap4.min.css';
import * as select2Config from './lib/select2.config'

// COOKIE CONSENT
import * as cookieConsentConfig from './lib/cookie-consent.config'

// MAGALONE FLIPBOOK
import './lib/magalone.min';
import './styles/commun/magalone.min.css'

// CUSTOM COLLECTIONS
import './styles/commun/collection.css';
import * as collectionConfig from './lib/collection.config'

// CUSTOM FICHIER
import './styles/commun/fichier.css';
import * as fichierConfig from './lib/fichier.config'

// CSS VISITEUR
import './styles/visiteur/slick.css';
import './styles/visiteur/slick-theme.css';
import './styles/visiteur/nice-select.css';
import './styles/visiteur/jquery.fancybox.css';
import './styles/visiteur/style.css';
import './styles/visiteur/add.css';

// JS VISITEUR
import ieDetector from './lib/visiteur/ie-detector'
import './lib/visiteur/ie-detector'
import './lib/visiteur/modernizr'
import './lib/visiteur/slick.min'
import './lib/visiteur/jquery.nice-select.min'
import './lib/visiteur/jquery.matchHeight-min'
import './lib/visiteur/mixitup.min'
import './lib/visiteur/jquery.fancybox.min'

// FULLCALENDAR
import 'fullcalendar/main.min.css'
import * as fullcalendarConfig from './lib/fullcalendar.config.js'

// STIMULUS APP
import './bootstrap';

// ROUTING
var Routing = require('./routing');

'use strict';

// sticky footer
//-----------------------------------------------------------------------------
if(!Modernizr.flexbox) {
    (function() {
        var
            $pageWrapper = $('#page-wrapper'),
            $pageBody = $('#page-body'),
            noFlexboxStickyFooter = function() {
                $pageBody.height('auto');
                if($pageBody.height() + $('#header').outerHeight() + $('#footer').outerHeight() < $(window).height()) {
                    $pageBody.height($(window).height() - $('#header').outerHeight() - $('#footer').outerHeight());
                } else {
                    $pageWrapper.height('auto');
                }
            };
        $(window).on('load resize', noFlexboxStickyFooter);
    })();
}
if (ieDetector.ieVersion == 10 || ieDetector.ieVersion == 11) {
    (function () {
      var
        $pageWrapper = $('#page-wrapper'),
        $pageBody = $('#page-body'),
        ieFlexboxFix = function () {
          if ($pageBody.addClass('flex-none').height() + $('#header').outerHeight() + $('#footer').outerHeight() < $(window).height()) {
            $pageWrapper.height($(window).height());
            $pageBody.removeClass('flex-none');
          } else {
            $pageWrapper.height('auto');
          }
        };
      ieFlexboxFix();
      $(window).on('load resize', ieFlexboxFix);
    })();
  }

  
$(document).ready(function() {

    collectionConfig.setCollectionManage();
    fichierConfig.setCustomFileInput();
    fichierConfig.setFichierDelete();
    summernoteConfig.setSummernote();
    flashBagFunctions.toastrFlashbag();
    imaskConfig.setImask();
    select2Config.setSelect2();
    cookieConsentConfig.setBanner();
    fullcalendarConfig.setFullCalendar();

    $('.btn-publicite').click(function(event)
    {
        event.preventDefault();
        var publiciteId = $(this).data('publicite-id');
        var href = $(this).attr('href');
        var url = Routing.generate('visiteur_publicite_increment_compteur_clic', {'id' : publiciteId});

        $.getJSON(url, function(json)
        {
            window.location = href;
        });
    });

    // Utilise le plugin jquery.validate pour tout les formulaires de l'application
    $('form:not([novalidate])').each(function()
    {
        $(this).validate();
    });

    // menu toggler
    $('.header-menu-toggler').on('click', function(e){
        e.preventDefault();
        $(this).toggleClass('active');
        $('.header-menu').toggleClass('active');
        $('body').toggleClass('w-mobile-menu');
    });

    $(window).scroll(function(){
        if ($(window).scrollTop() > 300) {
            $('.header').addClass('scroll');
        }
        else {
            $('.header').removeClass('scroll')
        }
    });

    $('.submenu-toggle-mobile').on('click', function(){
        $(this).toggleClass('active');
    });

//sliders settings
    $('#promo-slider, #interviews-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        speed: 1000,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        responsive: [
            {breakpoint: 992, settings: {slidesToShow: 1,}}
        ]
    });

    $('#companies-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 1000,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        responsive: [
            {breakpoint: 992, settings: {slidesToShow: 2,}},
            {breakpoint: 480, settings: {slidesToShow: 1,}}
        ]
    });

    $('#projects-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        speed: 1000,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        responsive: [
            {breakpoint: 992, settings: {slidesToShow: 3,}},
            {breakpoint: 768, settings: {slidesToShow: 2,}},
            {breakpoint: 480, settings: {slidesToShow: 1,}}
        ]
    });

    $('#promo-right-slider').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        dots: false,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
    });

//fly block togglerFullCalendar
    $(window).scroll(function(){
        if ($(window).scrollTop() > 800) {
            $('.fly-block').addClass('scroll');
        }
        else {
            $('.fly-block').removeClass('scroll');
        }
    });


     //same height for blocks
  function sameHeight(el) {
    el.matchHeight({
      byRow: true,
      property: 'height',
      target: null,
      remove: false
    });
  }

  sameHeight($('.same-height'));
//nice select
    $('select:not(.input-select2)').niceSelect();

    // Utilise le plugin jquery.validate pour tout les formulaires de l'application
    $('form:not([novalidate])').validate();

//calculate width for parent menu item
    // var subMenuEl = $('.submenu');
    // $.each(subMenuEl, function(){
    //   var subMenuWidth = $(this).width();
    //   var parentMenuEl = $(this).parent('.w-submenu');
    //   if ( subMenuWidth > parentMenuEl.width()){
    //     parentMenuEl.css('min-width', subMenuWidth);
    //   }
    // });

});