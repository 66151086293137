// COOKIE CONSENT
import Cookies from 'js-cookie';

export function setBanner()
{
    var cookieConsent = Cookies.get('consent');
    if (cookieConsent === undefined || cookieConsent === false) {
        $('#cookie-consent-container').removeClass('d-none');
        $('#cookie-consent-btn').click(function()
        {
            Cookies.set('consent', true);
            $('#cookie-consent-container').remove();
        });
    } else {
        $('#cookie-consent-container').remove();
    }
}